import React from 'react'
import cn from 'classnames'
import css from './style.module.scss'

const Loading = ({ expand }) => {
  const classNames = cn(css.loading, expand && css.expand)

  return <div className={classNames}>Loading...</div>
}

export default Loading
