import {
  eachDayOfInterval,
  subDays,
  subMonths,
  format,
  startOfDay,
  isToday,
  toDate,
} from 'date-fns'

export function eachMonthOfInterval(dirtyInterval) {
  if (arguments.length < 1) {
    throw new TypeError(
      '1 argument required, but only ' + arguments.length + ' present'
    )
  }

  var interval = dirtyInterval || {}
  var startDate = toDate(interval.start)
  var endDate = toDate(interval.end)
  var endTime = endDate.getTime()
  // Throw an exception if start date is after end date or if any date is `Invalid Date`
  if (!(startDate.getTime() <= endTime)) {
    throw new RangeError('Invalid interval')
  }

  var dates = []

  var currentDate = startDate
  currentDate.setHours(0, 0, 0, 0)
  currentDate.setDate(1)

  while (currentDate.getTime() <= endTime) {
    dates.push(toDate(currentDate))
    currentDate.setMonth(currentDate.getMonth() + 1)
  }

  return dates
}

export const calculateStreak = (atoms, date = new Date(), value = 0) => {
  if (atoms[format(date, 'dd/MM/yyyy')]) {
    return calculateStreak(atoms, subDays(date, 1), value + 1)
  }
  if (isToday(date)) {
    return calculateStreak(atoms, subDays(date, 1), value)
  }
  return value
}

export const calculateCalendar = (habit, numberOfMonths) => {
  const lastDate = subMonths(new Date(), numberOfMonths)
  const months = eachMonthOfInterval({
    start: lastDate,
    end: new Date(),
  })
  return months.reverse()
}

export const calculateMaxStreak = atoms => {
  // const daysWithActivity = Object.keys(atoms).filter(key => atoms[key]).map(a =>)
  // console.log('daysWithActivity', daysWithActivity)
  return 42
  // if (atoms[format(date, 'dd/MM/yyyy')]) {
  //   return calculateStreak(atoms, subDays(date, 1), value + 1)
  // }

  // return calculateStreak(atoms, subDays(date, 1), value + 1)
  // return value
}
