import { createAction, createReducer } from 'redux-act'
import { userSession } from 'utils/auth'

export const setStyle = createAction('setStyle')

export const setFirstLoadEnded = createAction('setFirstLoadEnded')

const defaultState = {
  style: {},
  firstLoadEnded: false,
}

export default createReducer(
  {
    [setFirstLoadEnded]: (state, payload) => {
      return {
        ...state,
        firstLoadEnded: true,
      }
    },
    [setStyle]: (state, payload) => {
      setClassOnDocumentBody(payload.theme)
      typeof window !== 'undefined' &&
        window.localStorage.setItem('theme', payload.theme)
      return {
        ...state,
        style: payload,
      }
    },
  },
  defaultState
)

const setClassOnDocumentBody = theme => {
  if (typeof window !== 'undefined') {
    window.theme = theme
  }
  if (typeof document !== 'undefined') {
    document.body.classList.remove(...['dark', 'light'])
    document.body.classList.add(theme)
  }
}

export const uiSideEffects = {
  [setStyle]: async (action, state) => {
    //add the article to the general file, minus the description. add a md5 of the title to use as ID
    await userSession.putFile('style.json', JSON.stringify(state.ui.style))
  },
}

const theme =
  (typeof window !== 'undefined' && window.localStorage.getItem('theme')) ||
  'light'
defaultState.style.theme = theme
setClassOnDocumentBody(theme)
