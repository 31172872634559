import React, { useState, useEffect, useRef } from 'react'
import css from './style.module.scss'
import { userSession } from 'utils/auth'
import Page from 'components/Page'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { calculateStreak, calculateCalendar } from 'utils/habits'
import Button from 'components/Button'
import Loading from 'components/Loading'
import {
  eachDayOfInterval,
  subDays,
  format,
  startOfDay,
  isToday,
  getDaysInMonth,
  endOfMonth,
  isFuture,
} from 'date-fns'
import { setAtom, addHabit } from 'reducers/habits'
import getColor from 'utils/colors'
import { store } from 'reducers'
import Icon from 'components/ui/Icon'
import HabitDetails from '../HabitDetails'

const HabitPage = ({ id }) => {
  const user = userSession.loadUserData()
  // const [numberOfMonths, setNumberOfMonths] = useState(1)
  // const habit = useSelector(
  //   state => console.log('state.habits', state) || state.habits.list[id]
  // )
  return (
    <Page withBack={true} withHeader={false}>
      <HabitDetails id={id} page={true} />
      {/* {!habit && <Loading />} */}
    </Page>
  )
}

export default HabitPage
