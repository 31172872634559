import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import Page from 'components/Page/index.js'
import { useSelector } from 'react-redux'
import { setStyle } from 'reducers/ui.js'
import { setHabit } from 'reducers/habits.js'
import { store } from 'reducers/index.js'
import css from './style.module.scss'
import Button from 'components/Button'
import Input, { Dropdown } from 'components/Inputs'
import { navigate } from '@reach/router'
import shortid from 'shortid'
const urlSchema = Yup.object().shape({
  name: Yup.string(),
  color: Yup.string(),
})

const colors = ['red', 'blue', 'orange', 'purple', 'green', 'pink']

const NewHabit = ({ onClose }) => {
  const onSubmit = async (values, actions) => {
    if (actions.validateForm()) {
      actions.setSubmitting(true)
      store.dispatch(setHabit({ ...values, id: shortid.generate(), atoms: {} }))
      navigate('/app')
    }
  }
  return (
    <Page withBack={true} withHeader={false}>
      <div className={css.container}>
        <Formik
          validationSchema={urlSchema}
          initialValues={{ name: '', color: 'red' }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting, values, errors, touched }) => (
            <div style={{ width: '100%' }}>
              <h2 className={css.title}>Add a new habit</h2>
              <form className={css.newUrlForm} onSubmit={handleSubmit}>
                <Field
                  placeholder="Read, Water, 100 Pushups..."
                  component={Input}
                  label="Name"
                  name="name"
                />
                <Field
                  component={Dropdown}
                  name="color"
                  label="Color"
                  options={colors}
                />
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Loading...' : 'Add Habit'}
                </Button>
              </form>
            </div>
          )}
        </Formik>
      </div>
    </Page>
  )
}

export default NewHabit
