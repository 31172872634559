import React, { useState, useEffect, useRef } from 'react'
import css from './style.module.scss'
import { userSession } from 'utils/auth'
import Page, { Back } from 'components/Page'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { calculateStreak, calculateCalendar } from 'utils/habits'
import Button from 'components/Button'
import Loading from 'components/Loading'

import {
  eachDayOfInterval,
  subDays,
  format,
  startOfDay,
  isToday,
  getDaysInMonth,
  endOfMonth,
  isFuture,
} from 'date-fns'
import { setAtom, addHabit } from 'reducers/habits'
import getColor from 'utils/colors'
import { store } from 'reducers'
import Icon from 'components/ui/Icon'

const f = date => format(date, 'dd/MM/yyyy')
const onClick = (date, done, habitId) => {
  store.dispatch(setAtom({ date: date, done, habitId }))
}

const Atom = ({ date, value, color, habitId, disabled, onClick }) => {
  const today = isToday(date)
  let style = {}

  if (!disabled) {
    style = !value
      ? {
          // color: getColor(color, !value),
          background: getColor(color, 3),
          color: getColor(color, 1),
          borderColor: today && getColor(color, 1),
          // borderColor: getColor(color, 0),
          // background: today && getColor(color, 1),
        }
      : {
          background: getColor(color, value ? 2 : 0),
          borderColor: today && getColor(color, 2),
        }
  }
  return (
    <div
      onClick={() => onClick(!value)}
      className={cn(
        css.atom,
        today && css.today,
        disabled && css.disabled,
        value ? css.full : css.empty
      )}
      style={style}
    >
      {format(date, 'dd')}
    </div>
  )
}
const Calendar = ({ date, habit }) => {
  // console.log('date', typeof date)
  const month = format(date, 'MMMM yyyy')

  const days = eachDayOfInterval({ start: date, end: endOfMonth(date) })
  // console.log('days', days)
  return (
    <div className={css.calendar}>
      <h3>{month}</h3>
      <div className={css.days}>
        {days.map(day => (
          <Atom
            key={day}
            date={day}
            disabled={isFuture(day)}
            value={habit.atoms[f(day)]}
            color={habit.color}
            habitId={habit.id}
            onClick={value => {
              onClick(day, value, habit.id)
            }}
          />
        ))}
      </div>
    </div>
  )
}

const Calendars = ({ calendars, habit }) => {
  return (
    <div className={css.calendars}>
      {calendars.map(calendar => (
        <Calendar key={calendar} date={calendar} habit={habit} />
      ))}
    </div>
  )
}

const Info = ({ value, label, color }) => {
  return (
    <div className={css.info}>
      <div className={css.label}>{label}</div>
      <div className={css.value} style={color}>
        {value}
      </div>
    </div>
  )
}

const HabitDetails = ({ id, page }) => {
  const user = userSession.loadUserData()
  const [numberOfMonths, setNumberOfMonths] = useState(5)
  const habit = useSelector(
    state => console.log('state.habits', state) || state.habits.list[id]
  )
  // console.log('ATOMS', habit.atoms)
  let streak = '-'
  let calendar = []
  let todayValue = false
  let total = 0
  if (habit) {
    // console.log('ATOMS', habit.atoms)
    streak = calculateStreak(habit.atoms)
    calendar = calculateCalendar(habit, numberOfMonths)
    todayValue = habit.atoms[f(new Date())]
    total = Object.keys(habit.atoms).reduce(
      (total, a) => (habit.atoms[a] ? total + 1 : total),
      0
    )
  }
  if (!habit) {
    return <Loading />
  }
  return (
    <div className={css.habitDetailContainer}>
      <div className={css.headerContainer}>
        {page && <Back />}
        <h2 style={{ color: getColor(habit.color, 2) }}>{habit.name}</h2>
        <div className={css.bottomSection}>
          <div className={css.infoContainer}>
            <Info
              label="Current streak"
              value={streak + ' days'}
              color={{ color: getColor(habit.color, 2) }}
            />
            {/* <Info
              label="Longest streak"
              value={habit.longestStreak + ' days'}
              color={{ color: getColor(habit.color, 2) }}
            /> */}
            <Info
              label="Total"
              value={total}
              color={{ color: getColor(habit.color, 2) }}
            />
          </div>
          <Button
            className={css.doneButton}
            big
            color={habit.color}
            onClick={() => onClick(new Date(), !todayValue, habit.id)}
          >
            {todayValue ? (
              '💪Done!'
            ) : (
              <>
                <Icon name="Done" /> Mark as done
              </>
            )}
          </Button>
        </div>
      </div>
      <div className={css.contentContainer}>
        <p>
          You can also mark as done any other day by clicking on the day on the
          calendar below
        </p>
        <Calendars calendars={calendar} habit={habit} />

        <Button
          secondary
          onClick={() => {
            setNumberOfMonths(numberOfMonths + 12)
          }}
        >
          Load more...
        </Button>
        <Button
          secondary
          onClick={() => {
            const colors = ['red', 'blue', 'orange', 'purple', 'pink', 'green']
            store.dispatch(
              addHabit({
                ...habit,
                color: colors[Math.floor(Math.random() * colors.length)],
              })
            )
          }}
        >
          Change Color
        </Button>
      </div>
    </div>
  )
}

export default HabitDetails
