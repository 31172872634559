import { createStore, combineReducers, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import habitsReducer, { habitsSideEffects } from './habits'
import uiReducer, { uiSideEffects } from './ui'

const reducers = combineReducers({
  // ...your other reducers here
  // you have to pass formReducer under 'form' key,
  // for custom keys look up the docs for 'getFormState'
  habits: habitsReducer,
  ui: uiReducer,
})

const sideEffects = {
  habits: habitsSideEffects,
  ui: uiSideEffects,
}

const sideEffectsMiddleware = store => next => action => {
  const returnedValue = next(action)
  console.log('after', action, store, store.getState())
  const currentState = store.getState()
  console.log(currentState)
  Object.keys(sideEffects).forEach(key => {
    if (sideEffects[key][action.type]) {
      console.log('found!')
      sideEffects[key][action.type](action, currentState)
    }
  })
  return returnedValue
}

export const store = createStore(
  reducers,
  applyMiddleware(sideEffectsMiddleware)
)

export default {
  store,
}
