// function hexToRgbA(hex, opacity = 1) {
//   console.log(hex)
//   var c
//   if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
//     c = hex.substring(1).split('')
//     if (c.length === 3) {
//       c = [c[0], c[0], c[1], c[1], c[2], c[2]]
//     }
//     c = '0x' + c.join('')
//     return (
//       'rgba(' +
//       [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
//       ',' +
//       opacity +
//       ')'
//     )
//   }
//   throw new Error('Bad Hex')
// }

const colors = {
  light: {
    red: '#F54A4A',
    redLight: '#FFC6C6',
    redLightest: '#FFD7D7',
    redStrong: '#F02020',

    blue: '#0067CD',
    blueLight: '#6DAAE8',
    blueLightest: '#C0DBF5',
    blueStrong: '#0067CD',

    orange: '#FF965A',
    orangeLight: '#FFB58B',
    orangeLightest: '#FFDFCD',
    orangeStrong: '#FF620A',

    green: '#27AE60',
    greenLight: '#68C690',
    greenLightest: '#BEE7CF',
    greenStrong: '#009941',

    purple: '#8A78EE',
    purpleLight: '#ADA1F3',
    purpleLightest: '#DCD7FA',
    purpleStrong: '#5C41F0',

    pink: '#FC97B4',
    pinkLight: '#FDB6CB',
    pinkLightest: '#FEE0E9',
    pinkStrong: '#FF5183',
  },
  dark: {
    red: '#B02020',
    redLight: '#FFC6C6',
    redLightest: '#FFD7D7',
    redStrong: '#B02020',

    blue: '#135FAB',
    blueLight: '#5A8FC4',
    blueLightest: '#89AFD5',
    blueStrong: '#135FAB',

    orange: '#B94605',
    orangeLight: '#FFB58B',
    orangeLightest: '#DCA282',
    orangeStrong: '#B94605',

    green: '#017E51',
    greenLight: '#68C690',
    greenLightest: '#6B9888',
    greenStrong: '#017E51',

    purple: '#443695',
    purpleLight: '#4DA585',
    purpleLightest: '#A19ACA',
    purpleStrong: '#443695',

    pink: '#9E3266',
    pinkLight: '#BB6F94',
    pinkLightest: '#CF98B3',
    pinkStrong: '#9E3266',
  },
}

const variations = ['Light', '', 'Strong', 'Lightest']

export const getColor = (colorName, intensity = 1) => {
  const theme = typeof window !== 'undefined' ? window.theme : 'light'
  return colors[theme][colorName + variations[intensity]]
}

export default getColor
