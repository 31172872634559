import React, { useState, useEffect } from 'react'
import { userSession } from 'utils/auth'
import { setStyle, setFirstLoadEnded } from 'reducers/ui.js'
import { store } from 'reducers/index.js'
import Loading from 'components/Loading'
import { addHabit, setOrder } from 'reducers/habits.js'
import { useSelector } from 'react-redux'

const loadStyle = async () => {
  const response = await userSession.getFile('style.json')
  const style = response
    ? JSON.parse(response)
    : {
        theme: 'dark',
      }
  store.dispatch(setStyle(style))
  return
}

const loadHabits = async () => {
  // await userSession.deleteFile("articles.json");
  const response = (await userSession.getFile('habits.json')) || '[]'
  console.log('????')
  const responseHabitsArray = JSON.parse(response)
  store.dispatch(setOrder(responseHabitsArray))
  console.log('responseHa', responseHabitsArray)
  const habitsPromises =
    responseHabitsArray.length === 0
      ? []
      : responseHabitsArray.map(id => {
          console.log('ID', id)
          return userSession
            .getFile('habits/' + id + '.json')
            .then(habitResponse => {
              const responseHabitObj = JSON.parse(habitResponse)

              store.dispatch(addHabit(responseHabitObj))
              // console.log('findHabit!', responseHabitObj)
              return responseHabitObj
            })
        })
  return Promise.all(habitsPromises)
}

const UserSignedInContainer = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const alreadyLoaded = useSelector(state => state.ui.firstLoadEnded)
  useEffect(
    () => {
      const init = async () => {
        if (!alreadyLoaded) {
          // await loadStyle()
          await loadHabits()

          store.dispatch(setFirstLoadEnded())
        }
        setLoaded(true)
      }
      init()
    },
    [alreadyLoaded]
  )

  return loaded ? children : <Loading expand />
}

export default UserSignedInContainer
