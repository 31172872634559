import { createAction, createReducer } from 'redux-act'
import { userSession } from 'utils/auth'
import { format } from 'date-fns'
import { calculateMaxStreak } from 'utils/habits'
//used on the first load
export const addHabit = createAction('addHabit')

//used after save. Saves also on Blockstack
export const setHabit = createAction('setHabit')
export const setAtom = createAction('addAtom')

export const setOrder = createAction('setOrder')
export const deleteHabits = createAction('deleteHabits')

const defaultState = { list: {}, order: [] }

//just for reference, not used
const defaultHabit = {
  id: '',
  name: '',
  color: '',
  atoms: {},
}

export const denormalizer = objects =>
  Object.keys(objects).map(key => objects[key])

export const normalizer = list => {
  return list.reduce((objects, article) => {
    objects[article.id] = article
    return objects
  }, {})
}

export default createReducer(
  {
    [deleteHabits]: (state, payload) => {
      return defaultState
    },
    [setOrder]: (state, payload) => {
      return {
        ...state,
        order: payload,
      }
    },
    [addHabit]: (state, payload) => {
      const habit = payload
      return {
        ...state,
        list: {
          ...state.list,
          [habit.id]: { longestStreak: 0, ...habit },
        },
      }
    },
    [setHabit]: (state, payload) => {
      const habit = payload
      return {
        ...state,
        list: {
          ...state.list,
          [habit.id]: { longestStreak: 0, ...habit },
        },
        order: state.order.concat([habit.id]),
      }
    },
    [setAtom]: (state, payload) => {
      const habit = state.list[payload.habitId]
      const newAtoms = {
        ...habit.atoms,
        [format(payload.date, 'dd/MM/yyyy')]: payload.done,
      }

      return {
        ...state,
        list: {
          ...state.list,
          [payload.habitId]: {
            ...habit,
            longestStreak: calculateMaxStreak(newAtoms),
            atoms: newAtoms,
          },
        },
      }
    },
  },

  defaultState
)

const saveHabits = async habitsOrder => {
  await userSession.putFile('habits.json', JSON.stringify(habitsOrder))
  return
}

const saveHabit = async habit => {
  return await userSession.putFile(
    'habits/' + habit.id + '.json',
    JSON.stringify(habit)
  )
}
const delHabit = async habit => {
  return await userSession.deleteFile('habits/' + habit.id + '.json')
}

export const habitsSideEffects = {
  [setHabit]: async (action, state) => {
    await saveHabits(state.habits.order)
    await saveHabit(state.habits.list[action.payload.id])
  },
  [setAtom]: async (action, state) => {
    await saveHabit(state.habits.list[action.payload.habitId])
  },
  // [deleteArticle]: async (action, state) => {
  //   await saveArticles(state.articles.list)
  //   await delArticle(action.payload)
  // },
  [deleteHabits]: async (action, state) => {
    await saveHabits(state.habits.order)
    alert('Done')
  },
}
