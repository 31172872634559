import React, { useRef } from 'react'
import cn from 'classnames'
import css from './style.module.scss'
import Icon from 'components/ui/Icon'

const isObject = function(a) {
  return !!a && a.constructor === Object
}

export const Dropdown = ({
  label,
  name,
  field,
  type,
  placeholder,
  value,
  onChange,
  options,
  isDisabled,
  isClearable,
  onCancel,
  nullable,
  responsive,
}) => {
  if (nullable) {
    options = [{ value: undefined, label: nullable }].concat(options)
  }
  const dropdownRef = useRef(null)
  const openDropdown = () => {
    dropdownRef.current.click()
  }
  return (
    <div className={cn(css.input, css.dropdown, isDisabled && css.disabled)}>
      {label && (
        <label htmlFor={name} className={css.label}>
          {label}
        </label>
      )}
      <label className={css.dropdownInput} htmlFor={name}>
        <select
          ref={dropdownRef}
          placeholder={placeholder}
          className={cn(
            css.inputField,
            css.selectField,
            responsive && css.responsive
          )}
          id={name}
          disabled={isDisabled}
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          defaultValue={value}
          {...field}
        >
          {options.map(v => {
            const opt = isObject(v) ? v : { value: v, label: v }
            return (
              <option value={opt.value} key={`option${opt.value}`}>
                {opt.label}
              </option>
            )
          })}
        </select>
        {isClearable && value && (
          <Icon
            name="Close"
            onClick={onCancel}
            className={cn(css.icon, css.close)}
          />
        )}
        <Icon name="ArrowDown" className={cn(css.icon, css.down)} />
      </label>
    </div>
  )
}

export const Checkbox = ({
  name,
  selected,
  children,
  onChange,
  className,
  label,
}) => {
  const onPress = () => {
    onChange(!selected)
  }
  return (
    <label className={css.checkboxWrapper} htmlFor={name}>
      <input
        id={name}
        className={cn(css.checkbox, className)}
        type="checkbox"
        checked={selected}
        onChange={onPress}
      />
      <div className={css.check} /> <span>{children || label}</span>
    </label>
  )
}

export const Label = ({ name, children }) => (
  <label htmlFor={name} className={css.label}>
    {children}
  </label>
)

export const Input = ({
  label,
  name,
  field,
  type,
  placeholder,
  value,
  onChange,
  big,
  className,
  responsive,
  description,
  errors,
  disabled,
}) => (
  <div className={cn(css.input, className)}>
    {label && (
      <div className={css.labelRow}>
        <Label name={name}>{label}</Label>
        {errors && <small className={css.errors}>{errors}</small>}
      </div>
    )}
    <input
      placeholder={placeholder}
      className={cn(
        css.inputField,
        responsive && css.responsive,
        big && css.big
      )}
      id={name}
      name={name}
      disabled={disabled}
      autoComplete="off"
      value={value}
      onChange={onChange}
      type={type}
      {...field}
    />
    {description && <small className={css.description}>{description}</small>}
  </div>
)

export default Input
