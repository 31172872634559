import React, { useState, useEffect, useRef } from 'react'
import css from './style.module.scss'
import { userSession } from 'utils/auth'
import Page from 'components/Page'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import Button from 'components/Button'
import { startOfDay, format } from 'date-fns'
import { setAtom } from 'reducers/habits'
import getColor from 'utils/colors'
import { calculateStreak } from 'utils/habits'
import { store } from 'reducers'
import Icon from 'components/ui/Icon'
import { navigate } from '@reach/router'
import { useMediaQuery } from 'react-responsive'
import HabitDetails from '../HabitDetails'
import PrivateRoute from 'components/PrivateRoute'

import { Router } from '@reach/router'

const NoHabitDetails = () => {
  return (
    <div className={css.noHabit}>Choose an habit to view the detail 💪</div>
  )
}

const Habit = ({ name, color, atoms = {}, id, loadMore, daysToLoad = 8 }) => {
  const streak = calculateStreak(atoms)
  const today = format(new Date(), 'dd/MM/yyyy')
  const doneToday = atoms[today]
  return (
    <div
      className={css.habit}
      onClick={() => {
        navigate('/app/habits/' + id)
      }}
      style={{ backgroundColor: getColor(color, 2) }}
    >
      <div className={css.title}>{name}</div>
      <div className={css.bottom}>
        <div className={css.streak}>
          <div className={css.label}>Streak</div>
          <div className={css.value}>{streak}</div>
        </div>
        <div
          className={css.done}
          onClick={e => {
            {
              e.preventDefault()
              e.stopPropagation()
              store.dispatch(
                setAtom({
                  date: new Date(),
                  done: !doneToday,
                  habitId: id,
                })
              )
              return false
            }
          }}
          // style={{ backgroundColor: getColor(color, 0) }}
        >
          {doneToday ? '💪' : <Icon name="Done" />}
        </div>
      </div>
    </div>
  )
}

const Homepage = () => {
  const user = userSession.loadUserData()
  console.log(user.profile)
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 961px)',
  })
  const { order, list } = useSelector(state => state.habits)
  const habits = order.reduce((habits, id) => {
    if (list[id]) {
      habits.push(list[id])
    }
    return habits
  }, [])
  return (
    <Page
      withBack={false}
      withBottomBar={
        isDesktopOrLaptop ? (
          undefined
        ) : (
          <Button className={css.newUrlButton} to="/app/habits/new">
            New Habit
          </Button>
        )
      }
    >
      <div className={css.mainPage}>
        <div className={css.sidebarContainer}>
          <div className={css.headerContainer}>
            <div className={css.habitsActions}>
              <h2>How is your day?</h2>
            </div>
          </div>
          <div className={css.habitsContainer}>
            {habits.length > 0 && (
              <>
                {habits.map(habit => (
                  <Habit key={habit.id} {...habit} />
                ))}
                <Button small className={css.newUrlButton} to="/app/habits/new">
                  New Habit
                </Button>
              </>
            )}
            {habits.length === 0 && (
              <div className={css.noHabits}>
                <h2>Create your first habit!</h2>
                <Button className={css.newUrlButton} to="/app/habits/new">
                  New Habit
                </Button>
              </div>
            )}
          </div>
        </div>

        {isDesktopOrLaptop && (
          <div className={css.detailContainer}>
            <Router>
              <PrivateRoute path="/habits/:id" component={HabitDetails} />
              <PrivateRoute path="/*" component={NoHabitDetails} />
            </Router>
          </div>
        )}
      </div>
    </Page>
  )
}

export default Homepage
