import React from 'react'
import { Router } from '@reach/router'
import PrivateRoute from '../components/PrivateRoute'
// import ArticlePage from 'components/appArticlePage'
import NewHabitPage from '../app/NewHabitPage'
import DebugPage from '../app/DebugPage'
import HabitsPage from '../app/HabitsPage'
import HabitPage from '../app/HabitPage'
import Page from '../components/Page'

import { Provider } from 'react-redux'
import { store } from 'reducers'
import { useMediaQuery } from 'react-responsive'

const App = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 961px)',
  })
  const isMobile = useMediaQuery({
    query: '(max-width: 960px)',
  })
  return (
    <Provider store={store}>
      <Router>
        <PublicRoute path="/app">
          <PrivateRoute path="/habits/new" component={NewHabitPage} />
          {isMobile && (
            <PrivateRoute path="/habits/:id" component={HabitPage} />
          )}
          {isMobile && <PrivateRoute path="/" component={HabitsPage} />}
          <PrivateRoute path="/__debug" component={DebugPage} />
          {isDesktopOrLaptop && (
            <PrivateRoute path="/*" component={HabitsPage} />
          )}
        </PublicRoute>
      </Router>
    </Provider>
  )
}
function PublicRoute(props) {
  return <>{props.children}</>
}

export default App
