import React from 'react'
import Close from './icons/close.svg'
import ArrowRight from './icons/arrowRight.svg'
import ArrowDown from './icons/arrowDown.svg'

import Done from './icons/done.svg'

const icons = {
  Close,
  ArrowRight,
  ArrowDown,
  Done,
}
const Icon = ({ name, className, style, size, onClick }) => {
  const Component = icons[name]
  return <Component onClick={onClick} className={className} style={style} />
}

export default Icon
